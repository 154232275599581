@import url("https://fonts.cdnfonts.com/css/poppins");

body {
  margin: 0;
  font-family: "Poppins, sans-serif !important";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.rdrDefinedRangesWrapper {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
