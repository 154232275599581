@import url("https://fonts.cdnfonts.com/css/poppins");

body {
  overflow: hidden;
  background-color: rgb(250, 250, 251) !important;
}

@media only screen and (max-width: 900px) {
  body {
    overflow: auto;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rdrDefinedRangesWrapper {
  display: none;
}
.rdrDateRangePickerWrapper {
  width: 100%;
  justify-content: center;
}
.rdrCalendarWrapper {
  /* font-size: 10.5px; */
  font-size: 12px;
}
.rdrDateDisplayWrapper {
  display: none;
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  display: none;
}
