.main-container {
  margin: 0;
  padding: 0;
  height: 100vh;
  min-height: 100vh; /* Agregar un tamaño mínimo */
  background-image: url('../../assets/image/fondo_login.png');
  background-repeat: no-repeat;
  background-size: cover; /* Utilizar 'cover' para ajustar la imagen */
  background-position: center; /* Centrar la imagen */
}

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.course-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .course-card {
    margin: 10px;
    max-width: 300px;
  }