.login-main-container{
    height: 100vh;
    background-image: url('../../assets/image/fondo_login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%
}

.login-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-container{
    text-align: center;
}